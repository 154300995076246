<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <pro-document-breadcrumbs
        :breadcrumbs="breadcrumbs"
        user-role="procurer"
      ></pro-document-breadcrumbs>
      <pro-calendar class="q-pa-md" />
    </el-main>
  </el-container>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProCalendar from "@/components/PROSmart/General/ProCalendar.vue";

export default {
  components: { ProDocumentBreadcrumbs, ProCalendar },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.Calendar",
          icon: "PROSmart-Calendar",
        },
      ],
    };
  },
  methods: {},
};
</script>
