var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row q-col-gutter-md"},[_c('div',{staticClass:"col-9 q-gutter-md"},[_c('div',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.getRes("Calendar.MyCalendar")))]),_c('div',[_c('vc-calendar',{attrs:{"attributes":_vm.vcAttributes,"masks":_vm.masks,"locale":_vm.locale,"is-expanded":""},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
var day = ref.day;
var attributes = ref.attributes;
return [_c('q-responsive',{staticStyle:{"width":"100%"},attrs:{"ratio":4 / 3}},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"col-auto"},[_c('q-btn',{attrs:{"no-caps":"","round":"","dense":"","flat":"","size":"1.5rem","label":day.day,"text-color":_vm.isNonWorkingDay(day.date) ? 'red' : 'black',"loading":_vm.$proSmart.common.format.date(day.date) === _vm.dateSelected},on:{"click":function($event){_vm.dateSelected = _vm.$proSmart.common.format.date(day.date)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(day.day))])]},proxy:true}],null,true)})],1),(attributes && attributes.length > 0)?_c('q-scroll-area',{staticClass:"col"},[_c('q-list',{staticClass:"q-mx-xs",attrs:{"separator":"","bordered":"","dense":""}},_vm._l((attributes),function(attr){return _c('q-item',{key:attr.key,attrs:{"clickable":true},nativeOn:{"click":function($event){_vm.dateSelected = _vm.$proSmart.common.format.date(
                          new Date(day.date)
                        )}}},[_c('q-item-section',[(attr.customData.tendererName)?_c('q-item-label',{attrs:{"lines":"1"}},[_vm._v(_vm._s(attr.customData.description)+" - "+_vm._s(attr.customData.tendererName))]):_c('q-item-label',{attrs:{"lines":"1"}},[_vm._v(_vm._s(attr.customData.description))])],1)],1)}),1)],1):_vm._e()],1)])]}}])})],1)]),_c('div',{staticClass:"col-3 q-gutter-md"},[_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(String.format(_vm.getRes("Calendar.MyMeetings"), _vm.dateSelected))+" ")]),_c('q-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.eventMap[_vm.dateSelected] && _vm.eventMap[_vm.dateSelected].length > 0),expression:"eventMap[dateSelected] && eventMap[dateSelected].length > 0"}],staticClass:"overflow-hidden"},[_c('q-list',{attrs:{"separator":""}},_vm._l((_vm.eventMap[_vm.dateSelected]),function(event){return _c('q-item',{key:event.id,attrs:{"to":_vm.getRoute(event)}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-h4"},[_vm._v(_vm._s(event.description))]),_c('q-item-label',{staticClass:"text-h6 text-grey-6"},[_vm._v(_vm._s(event.ref))]),_c('q-item-label',{staticClass:"text-h6 text-grey-6"},[_vm._v(_vm._s(event.subjEng))]),(event.tendererName)?_c('q-item-label',{staticClass:"text-h6 text-grey-6"},[_vm._v("Tenderer: "+_vm._s(event.tendererName))]):_vm._e(),_c('q-item-label',[_vm._v(_vm._s(_vm.getDateStr(event.startDate, event.endDate)))])],1)],1)}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.eventMap[_vm.dateSelected] || _vm.eventMap[_vm.dateSelected].length === 0
        ),expression:"\n          !eventMap[dateSelected] || eventMap[dateSelected].length === 0\n        "}]},[_vm._v(" "+_vm._s(_vm.getRes("Calendar.NoMeetings"))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }