<template>
  <div>
    <div class="row q-col-gutter-md">
      <div class="col-9 q-gutter-md">
        <div class="text-h4">{{ getRes("Calendar.MyCalendar") }}</div>
        <div>
          <vc-calendar
            :attributes="vcAttributes"
            :masks="masks"
            :locale="locale"
            is-expanded
          >
            <template v-slot:day-content="{ day, attributes }">
              <q-responsive :ratio="4 / 3" style="width: 100%">
                <div class="column">
                  <div class="col-auto">
                    <q-btn
                      no-caps
                      round
                      dense
                      flat
                      size="1.5rem"
                      :label="day.day"
                      :text-color="isNonWorkingDay(day.date) ? 'red' : 'black'"
                      :loading="
                        $proSmart.common.format.date(day.date) === dateSelected
                      "
                      @click="
                        dateSelected = $proSmart.common.format.date(day.date)
                      "
                    >
                      <template v-slot:loading>
                        <span class="text-bold">{{ day.day }}</span>
                      </template>
                    </q-btn>
                  </div>
                  <q-scroll-area
                    v-if="attributes && attributes.length > 0"
                    class="col"
                  >
                    <q-list class="q-mx-xs" separator bordered dense>
                      <q-item
                        v-for="attr in attributes"
                        :key="attr.key"
                        :clickable="true"
                        @click.native="
                          dateSelected = $proSmart.common.format.date(
                            new Date(day.date)
                          )
                        "
                      >
                        <q-item-section>
                          <q-item-label
                            lines="1"
                            v-if="attr.customData.tendererName"
                            >{{ attr.customData.description }} -
                            {{ attr.customData.tendererName }}</q-item-label
                          >
                          <q-item-label lines="1" v-else>{{
                            attr.customData.description
                          }}</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-scroll-area>
                </div>
              </q-responsive>
            </template>
          </vc-calendar>
        </div>
      </div>
      <div class="col-3 q-gutter-md">
        <div class="text-h4">
          {{ String.format(getRes("Calendar.MyMeetings"), dateSelected) }}
        </div>
        <q-card
          class="overflow-hidden"
          v-show="eventMap[dateSelected] && eventMap[dateSelected].length > 0"
        >
          <q-list separator>
            <q-item
              v-for="event in eventMap[dateSelected]"
              :key="event.id"
              :to="getRoute(event)"
            >
              <q-item-section>
                <q-item-label class="text-h4">{{
                  event.description
                }}</q-item-label>
                <q-item-label class="text-h6 text-grey-6">{{
                  event.ref
                }}</q-item-label>
                <q-item-label class="text-h6 text-grey-6">{{
                  event.subjEng
                }}</q-item-label>
                <q-item-label
                  class="text-h6 text-grey-6"
                  v-if="event.tendererName"
                  >Tenderer: {{ event.tendererName }}</q-item-label
                >
                <q-item-label>{{
                  getDateStr(event.startDate, event.endDate)
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
        <div
          v-show="
            !eventMap[dateSelected] || eventMap[dateSelected].length === 0
          "
        >
          {{ getRes("Calendar.NoMeetings") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLanguage } from "@/util";
import proSmart from "@pro-smart/hcm-custom-project-api-middleware";

export default {
  name: "ProCalendar",
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      locale: getLanguage(),
      eventList: [],
      nonWorkingDayMap: {},
      dateSelected: this.$proSmart.common.format.date(new Date()),
    };
  },
  computed: {
    vcAttributes() {
      return this.eventList.map((event) => ({
        key: event.id,
        customData: {
          description: event.description,
          tendererName: event.tendererName,
        },
        dates:
          event.startDate && event.endDate
            ? event.endDate > event.startDate
              ? { start: event.startDate, end: event.endDate }
              : { start: event.endDate, end: event.startDate }
            : event.startDate
            ? event.startDate
            : event.endDate,
      }));
    },
    eventMap() {
      let startDate, endDate;
      return this.eventList.reduce((map, elem) => {
        if (elem.startDate && elem.endDate) {
          if (elem.endDate >= elem.startDate) {
            startDate = elem.startDate;
            endDate = elem.endDate;
          } else {
            startDate = elem.endDate;
            endDate = elem.startDate;
          }
          let date = startDate.clearTime(true);
          while (date <= endDate) {
            this.pushMapOfArrays(
              map,
              this.$proSmart.common.format.date(date),
              elem
            );
            date.setDate(date.getDate() + 1);
          }
        } else if (elem.startDate) {
          this.pushMapOfArrays(
            map,
            this.$proSmart.common.format.date(elem.startDate),
            elem
          );
        } else if (elem.endDate) {
          this.pushMapOfArrays(
            map,
            this.$proSmart.common.format.date(elem.endDate),
            elem
          );
        }
        return map;
      }, {});
    },
  },
  methods: {
    isNonWorkingDay(date) {
      return !!this.nonWorkingDayMap[this.$proSmart.common.format.date(date)];
    },
    getDateStr(startDate, endDate) {
      if (startDate && endDate) {
        if (endDate > startDate) {
          return `${this.$proSmart.common.getFormattedDate(
            startDate
          )} - ${this.$proSmart.common.getFormattedDate(endDate)}`;
        } else {
          return `${this.$proSmart.common.getFormattedDate(
            endDate
          )} - ${this.$proSmart.common.getFormattedDate(startDate)}`;
        }
      } else if (startDate) {
        return this.$proSmart.common.getFormattedDate(startDate);
      } else if (endDate) {
        return this.$proSmart.common.getFormattedDate(endDate);
      }

      return "";
    },
    pushMapOfArrays(map, key, value) {
      if (!map[key]) {
        map[key] = [value];
      } else {
        map[key].push(value);
      }
    },
    getRoute(meeting) {
      if (!meeting.isBuyer) return {};

      return {
        name: "DocumentViewMeetingRequestDetail",
        params: {
          id: meeting.tenderId,
          userRole: "ProSmartBuyer",
          category: "meetingRequest",
          requestType: "view",
          meetingId: meeting.id,
        },
      };
    },
  },
  async created() {
    proSmart.codeTable.getNonWorkingDate(this).then((nonWorkingDayList) => {
      this.nonWorkingDayMap = nonWorkingDayList.reduce((map, elem) => {
        map[
          this.$proSmart.common.getFormattedDate(new Date(elem.calendarDate))
        ] = true;
        return map;
      }, {});
    });
    await this.$proSmart.procurerUi.getProcurerMeeting(this).then((list) => {
      this.eventList = list.map((meeting) => ({
        id: meeting.id,
        description: meeting.purpose,
        ref: meeting.tenderRef,
        subjEng: meeting.location,
        startDate:
          meeting.startDate != null ? new Date(meeting.startDate) : null,
        endDate: meeting.endDate != null ? new Date(meeting.endDate) : null,
        tenderId: meeting.tenderId,
        tendererId: meeting.tendererId,
        tendererName: meeting.tendererName,
        isBuyer: meeting.isBuyer,
      }));
    });

    // this.$proSmart.CodeTable.getNonWorkingDate(this).then(
    //   (nonWorkingDayList) => {
    //     this.nonWorkingDayMap = nonWorkingDayList.reduce((map, elem) => {
    //       map[
    //         this.$proSmart.common.format.date(new Date(elem.calendarDate))
    //       ] = true;
    //       return map;
    //     }, {});
    //   }
    // );
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";

/deep/ .vc-container {
  .vc-weeks {
    padding: 0;

    .vc-weekday {
      border-bottom: 1px solid $grey-5;
      color: $primary !important;
    }
  }

  .vc-day {
    &.on-left {
      margin-right: 0;
    }
    &.on-right {
      margin-left: 0;
    }
    &:not(.on-bottom) {
      border-bottom: 1px solid $grey-5;
    }
    &:not(.on-right) {
      border-right: 1px solid $grey-5;
    }

    .q-list {
      background-color: $blue-2;

      .q-item {
        min-height: 0;
        padding: 3px 8px;
      }
    }
  }
}

.text-h4 {
  color: $primary !important;
}
</style>
